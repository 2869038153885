import axios from 'axios';
import { ENDPOINTS_OPERATIONS } from '../constants/endpoint';

export const assignQR = async (data, token) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    }
    try {
        const res = await axios.post(ENDPOINTS_OPERATIONS.ASSIGN_QR, data, config)
        return res;
    } catch(e) {
        return {
            data: {
                error: true,
                code: e.code
            }
        };
    }
}

export const storeOperation = async (data, token) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    }
    try {
        const res = await axios.post(ENDPOINTS_OPERATIONS.STORE, data, config)
        return res;
    } catch(e) {
        return {
            data: {
                error: true,
                code: e.code
            }
        };
    }
}

export const getTodayTasks = async (token) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    }
    try {
        const res = await axios.get(ENDPOINTS_OPERATIONS.TASKS, config)
        return res;
    } catch(e) {
        return {
            data: {
                error: true,
                code: e.code
            }
        };
    }
}