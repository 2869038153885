import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { faBars, faUser, faSignOutAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logo from '../../assets/images/logo.png';

const Navbar = ({ currentToggle, toggle }) => {

  const sideNavbarToggle = () => {
    toggle((currentToggle=='')?'sb-sidenav-toggled':'')
  }

  return (
  <nav className="sb-topnav navbar navbar-expand navbar-light"  style={{ backgroundColor: '#fff' }}>
    <Link className="navbar-brand ps-3" to="/">
      <img src={logo} alt="Logo" height="36"/>
    </Link>
    <button className="btn btn-link btn-sm order-1 order-lg-0 ms-auto me-3 me-lg-4" id="sidebarToggle" onClick={sideNavbarToggle}>
      <FontAwesomeIcon icon={faBars}/>
    </button>
  </nav>);
};

export default Navbar;