import React, { useContext, useState } from "react";
import {faSync, faQrcode, faTasks, faSignOutAlt, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SideMenuItem from "./SideMenuItem";

import { useAuth } from "../../hooks";

const Sidebar = ({ currentToggle, toggle }) => {

    const {userLogOut, user, storedUser} = useAuth();
    const username = user.username||storedUser.username;

    const logOut = (e) => {
        e.preventDefault();
        userLogOut();
    }

    const sideNavbarToggle = () => {
        console.log('TEST')
        toggle((currentToggle=='')?'sb-sidenav-toggled':'')
    }

    return (
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
        <div className="sb-sidenav-menu">
        <div className="nav">
            <SideMenuItem label="Home" to="/" icon={<FontAwesomeIcon icon={ faHome }/>} handleClick={sideNavbarToggle}/>
            <SideMenuItem label="Scanner" to="/operation" icon={<FontAwesomeIcon icon={ faQrcode }/>} handleClick={sideNavbarToggle}/>
            <SideMenuItem label="Circuito" to="/circuit" icon={<FontAwesomeIcon icon={ faTasks }/>} handleClick={sideNavbarToggle}/>
            <SideMenuItem label="Logout" icon={<FontAwesomeIcon icon={ faSignOutAlt }/>} handleClick={logOut} />
        </div>
        </div>
        <div className="sb-sidenav-footer">
        <div className="small">Logged in as:</div> { username  }
        
        </div>
    </nav>
    );
};

export default Sidebar;
