import axios from 'axios';
import { ENDPOINTS_INITIAL_DATAS } from '../constants/endpoint';

export const getunassignedQR = async (token) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    }
    try {
        const res = await axios.get(ENDPOINTS_INITIAL_DATAS.UNASSIGNED_QR, config)
        return res;
    } catch(e) {
        return {
            data: {
                error: true,
                code: e.code
            }
        };
    }
}

export const getClearProducts = async (token) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    }
    try {
        const res = await axios.get(ENDPOINTS_INITIAL_DATAS.CLEAR_PRODUCTS, config)
        return res;
    } catch(e) {
        return {
            data: {
                error: true,
                code: e.code
            }
        };
    }
}
