import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { INITIAL_DATAS } from "../../constants/initials";
import { INITIAL_DATAS_TEXTS } from "../../constants/texts";

import { getunassignedQR, getClearProducts } from "../../api/apiInitialDatas";

export const getData = createAsyncThunk('initialDatas/get', async (token) => {
    const resQR = await getunassignedQR(token);
    const resProducts = await getClearProducts(token);
    
    if(resQR.data.error || resProducts.data.error) {
        return {
            error: true,
            code: 'GENERIC'
        }
    }

    return {
        unassignedQR: resQR.data.data, 
        clearProducts: resProducts.data.data
    };
})

export const initialDatas = createSlice({
    name: "initialDatas",
    initialState: {
        loadingInitialDatas: false,
        errorInitialDatas: undefined,
        initialDatas: INITIAL_DATAS.CLEAR
    },
    reducers: {
        clearErrorsInitialDatas:(state, action) => {
            state.errorInitialDatas = undefined;
        },
        setInitialDatas:(state, action) => {
            state.loadingInitialDatas = false;
            state.errorInitialDatas = undefined;
            state.initialDatas = {
                unassignedQR: action.payload.unassignedQR, 
                clearProducts: action.payload.clearProducts
            }
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getData.pending, (state, action) => {
            state.loadingInitialDatas = true;
            state.errorInitialDatas = undefined;
        })
        .addCase(getData.fulfilled, (state, action) => {
            state.loadingInitialDatas = false;
            state.errorInitialDatas = action.payload.error ? INITIAL_DATAS_TEXTS.ERRORS[action.payload.code] : undefined;
            state.initialDatas = {
                unassignedQR: action.payload.unassignedQR ? action.payload.unassignedQR : undefined, 
                clearProducts: action.payload.clearProducts ? action.payload.clearProducts : undefined
            }
        })
        .addCase(getData.rejected, (state, action) => {
            state.loadingInitialDatas = false;
            state.errorInitialDatas = INITIAL_DATAS_TEXTS.ERRORS[action.payload.code];
        })
    }
});

export const { clearErrorsInitialDatas, setInitialDatas } = initialDatas.actions;
export default initialDatas.reducer;
