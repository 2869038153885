import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';

import "../../assets/css/styles.css";

const PublicLayout = () => {
  const [toggledSidebar, setToggledSidebar] = useState('');

  return (
    <div className="" style={{backgroundColor: '#021524'}}>
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  )
}

export default PublicLayout;
