export const INITIAL_USER = {
    AUTH: {
        isAuth: false,
        token: undefined,
        username: undefined,
        remember: false
    },
    FORM: { 
        email: "", 
        password: "", 
        remember: false 
    }
}

export const INITIAL_DATAS = {
    CLEAR: {
        unassignedQR:[], 
        clearProducts:[]
    },
}

export const INITIAL_OPERATION = { 
    INPUT: {
        qr_code_id: undefined, 
        latitude: undefined, 
        longitude: undefined, 
        desc:undefined,
        product_id:undefined, 
        time:undefined 
    },
    NAME : {
        'op_1' : 'Installazione',
        'op_2' : 'Manutenzione',
        'op_3' : 'Spostamento'
    }
}
