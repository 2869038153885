import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Scanner, Loader } from '../../components';
import { usePosition, useConnectionStatus, useInitialDatas, useAuth } from '../../hooks';
import { assignQR, storeOperation } from '../../api/apiOperation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifi, faLocationDot } from '@fortawesome/free-solid-svg-icons'

import { idbConfig } from './configs/idbConfig';
import setupIndexedDB, { useIndexedDBStore } from "use-indexeddb";

import Manager from './components/Manager';

const Operation = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [dataResult, setDataResult] = useState();
  const [loadingOperation, setLoadingOperation] = useState(false);

  const { user, storedUser } = useAuth();
    
  const navigate = useNavigate();

  const {
    latitude,
    longitude,
    speed,
    timestamp,
    accuracy,
    heading,
    error,
  } = usePosition(true, { timeout: 3000, maximumAge: 0 });
  const { connectionStatus } = useConnectionStatus();
  const { getInitialDatas, initialDatas, storedInitialDatas, loadingInitialDatas, errorInitialDatas } = useInitialDatas();

  const { getAll, deleteByID } = useIndexedDBStore("operations");

  useEffect(() => {
      setTimeout(()=>setPageLoading(false), 3000);
      getInitialDatas();
      setupIndexedDB(idbConfig)
          .then(() => {
            getAll().then(op => {
              syncIDB(op);
            });
            console.log("IDB collegato")
          })
          .catch(e => console.error("IDB errore", e));
  }, []);

 
  const getResult = (result, error) => {
    connectionStatus && getInitialDatas();

    if(!result || !result.text) {
      error && console.log(error)
      return false;
    }
    const QRId = result.text;
    const isUnassigned = initialDatas.unassignedQR && initialDatas.unassignedQR.find(elem => elem.id == QRId) ? true : false;
    
    if(storedInitialDatas.unassignedQR.length>0 && storedInitialDatas.clearProducts.length>0){
      setDataResult({
        id: QRId,
        isUnassigned,
        latitude,
        longitude,
        accuracy,
        productsArray:initialDatas.clearProducts
      })
      return true;
    } 
    console.log('Mancano i dati iniziali')
    alert('Non è possibile eseguire questa operazione.')
  }

  const handleReset = () => {
    setDataResult(null)
  }

  useEffect(() => {
      connectionStatus && getInitialDatas();
      connectionStatus && setupIndexedDB(idbConfig)
      .then(() => {
        getAll().then(op => {
          syncIDB(op);
        });
        console.log("IDB collegato")
      })
      .catch(e => console.error("IDB errore", e));
  }, [connectionStatus]);

  
  const syncIDB = (op) => {
    const token = user.token||storedUser.token;
    (op && op.length>0) && op.forEach(async (item)=>{
      const res = (item.operation_type_id==1) ? await assignQR(item, token) : await storeOperation(item, token);
      console.log('Salvataggio operazione:', item)
      if(res.data && res.data.error) {
        console.log('Salvataggio non riuscito', item.id)
        return false;
      }
      console.log('Cancello da IDB', item.id)
      deleteByID(item.id)
    })
  }

  //const refInput = useRef();

  return (
    <>
    {pageLoading  && <Loader loading={pageLoading} />}
    {loadingOperation && <Loader loading={loadingOperation} />}
      <div className="text-center py-3 h5">
        <span>{dataResult ? `Operazione su ${dataResult.id}`: 'Effettua Scansione'}</span>
        <span className="mx-2"><FontAwesomeIcon className={(latitude&&longitude)?'text-primary':'text-danger' } icon={faLocationDot} /></span>
        <span className="mx-2"><FontAwesomeIcon className={(connectionStatus)?'text-primary':'text-danger' } icon={faWifi} /></span>
      </div>
      
      {!dataResult && <Scanner handleResult={getResult}/>}
      {/* <input ref={refInput} type="text" /><br/>
      <span className="btn btn-primary" onClick={()=>getResult({text:refInput.current.value})}>Scan</span> */}
      {dataResult && <Manager { ...dataResult } resetOperations={handleReset} loading={loadingOperation} setLoading={setLoadingOperation}/>}
    </>
  )
}

export default Operation;
