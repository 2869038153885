import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";

import "../../assets/css/styles.css";

const AuthLayout = () => {
  const [toggledSidebar, setToggledSidebar] = useState('');

  return (
    <div className={`sb-nav-fixed ${toggledSidebar}`}>
      <Navbar currentToggle={toggledSidebar} toggle={setToggledSidebar}/>
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <Sidebar currentToggle={toggledSidebar} toggle={setToggledSidebar} />
        </div>
        <div id="layoutSidenav_content">
          <main className="container p-5">
            <Outlet />
          </main>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default AuthLayout;
