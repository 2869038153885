import React from 'react'
import { ClipLoader } from 'react-spinners'

import "../assets/style/loader.css"

const Loader = ({loading}) => {
  return (
    <div className="wrapperLoader">
      <div className="contentLoader">
        <ClipLoader
            color={'red'}
            loading={loading}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
      </div>
    </div>
  )
}

export default Loader