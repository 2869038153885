import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getData, clearErrorsInitialDatas, setInitialDatas } from '../redux/slices/initialDatasSlice';

import { useLocalStorage, useAuth } from '.';

import { INITIAL_DATAS } from '../constants/initials';

const useInitialsData = () => {
    const { user, storedUser } = useAuth();
    const { initialDatas, loadingInitialDatas, errorInitialDatas } = useSelector(state => state.initialDatas)
    const [ storedInitialDatas, setStoredInitialDatas, deleteStoredInitialDatas ] = useLocalStorage('initialDatas', INITIAL_DATAS.CLEAR)

    const dispatch = useDispatch();

    useEffect(() => {
        if(initialDatas.unassignedQR && initialDatas.clearProducts && initialDatas.unassignedQR.length > 0 && initialDatas.clearProducts.length > 0) {
            setStoredInitialDatas(initialDatas);
        }
    }, [initialDatas]);

    useEffect(() => {
        if((!initialDatas.unassignedQR || !initialDatas.clearProducts) && (storedInitialDatas.unassignedQR && storedInitialDatas.clearProducts)) {
            dispatch(setInitialDatas(storedInitialDatas));
        }
    }, [storedInitialDatas]);

    useEffect(() => {
        dispatch(clearErrorsInitialDatas());
    }, [dispatch]);

    const getInitialDatas = () => {
        console.log('Recuper i dati iniziali - unassignedQR, clearProducts');
        dispatch(getData(user.token||storedUser.token));
    }

    return { getInitialDatas, initialDatas, storedInitialDatas, loadingInitialDatas, errorInitialDatas };
}

export default useInitialsData;