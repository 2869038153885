import React, { useState } from 'react'
import { QrScanner } from '@yudiel/react-qr-scanner'


const Scanner = ({handleResult, handleDecode, handleError}) => {
  return (
    <>
      <QrScanner
          onResult={(result, error)=>handleResult(result, error) }
          // onDecode={}
          onError={()=>{alert('Non è stato possibile effettuare la scansione')}}
      />
  </>
  
  )
}

export default Scanner