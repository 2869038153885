const BASE_URL_DEV = `http://crm.panda.test/api`
const BASE_URL_PROD = `https://app.ilpandasrl.it/api`

const BASE_URL = BASE_URL_PROD

export const URL_MAPS = {
    PATH: 'https://www.google.it/maps',
    SEARCH: '/search/?api=1&query=',
    DIR: '/dir/',
}

export const ENDPOINTS_LOGIN = {
    LOGIN: `${BASE_URL}/login`,
    LOGOUT: `${BASE_URL}/logout`,
}

export const ENDPOINTS_INITIAL_DATAS = {
    UNASSIGNED_QR: `${BASE_URL}/get_qr_without_products`,
    CLEAR_PRODUCTS: `${BASE_URL}/get_products_without_qr_code`,
}

export const ENDPOINTS_OPERATIONS = {
    ASSIGN_QR: `${BASE_URL}/assegna_qr_code_to_product`,
    STORE: `${BASE_URL}/store_operation`,
    TASKS: `${BASE_URL}/get_today_tasks`,
}