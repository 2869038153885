import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userLogin } from "../../api/apiLogin";
import { INITIAL_USER } from "../../constants/initials";
import { LOGIN_TEXTS } from "../../constants/texts";

export const logIn = createAsyncThunk('user/login', async (data) => {
    const res = await userLogin(data);
    
    console.log({...res.data, remember: data.remember})
    return {...res.data, remember: data.remember};
})

export const user = createSlice({
    name: "user",
    initialState: {
        loadingUser: false,
        errorUser: undefined,
        user: INITIAL_USER.AUTH
    },
    reducers: {
        clearErrorsUsers:(state, action) => {
            state.errorUser = undefined;
        },
        logOut:(state, action) => {
            state.loadingUser = false;
            state.errorUser = undefined;
            state.user = INITIAL_USER.AUTH;
        },
        setUser: (state, action) => {
            state.loadingUser = false;
            state.errorUser = undefined;
            state.user = {
                isAuth: action.payload.isAuth,
                token: action.payload.token,
                username: action.payload.username,
                remember: action.payload.remember,
            }
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(logIn.pending, (state, action) => {
            state.loadingUser = true;
            state.errorUser = undefined;
        })
        .addCase(logIn.fulfilled, (state, action) => {
            console.log(action)
            state.loadingUser = false;
            state.errorUser = action.payload.errorUser ? LOGIN_TEXTS.ERRORS[action.payload.code] : undefined;
            state.user = {
                isAuth: action.payload.token ? true : false,
                token: action.payload.token ? action.payload.token : undefined,
                username: action.payload.username ? action.payload.username : undefined,
                remember: action.payload.remember ? action.payload.remember : false,
            }
        })
        .addCase(logIn.rejected, (state, action) => {
            state.loadingUser = false;
            state.errorUser = LOGIN_TEXTS.ERRORS[action.payload.code];
        })
    }
});

export const { clearErrorsUsers, logOut, setUser } = user.actions;
export default user.reducer;
