import React, { useState, useEffect } from 'react'

const useConnectionStatus = () => {
    const [connectionStatus, setConnectionStatus] = useState(navigator.onLine);

    const checkConnectionSpeed = () => {
        const connection = navigator.connection;
        if (connection) {
            return {effectiveType: connection.effectiveType, downlink: connection.downlink};
        } 
        
        return {effectiveType: '', downlink: ''};
    };

    useEffect(() => {
        const checkConnectionStatus = () => {
            let status= navigator.onLine;
            setConnectionStatus(status);
            console.log(`Status: ${status?'Online':'Offline'}`);
        };

        window.addEventListener('online', checkConnectionStatus);
        window.addEventListener('offline', checkConnectionStatus);
    
        return () => {
          window.removeEventListener('online', checkConnectionStatus);
          window.removeEventListener('offline', checkConnectionStatus);
        };
    }, [connectionStatus]);

    return {connectionStatus, checkConnectionSpeed}
}

export default useConnectionStatus;
