import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/userSlice";
import initialDatasReducer from "../slices/initialDatasSlice";

export default configureStore({
    reducer: {
        user: userReducer,
        initialDatas: initialDatasReducer
    }
});
