import React, { useEffect, useState } from 'react'
import { useConnectionStatus, useAuth } from '../../../hooks';
import { assignQR, storeOperation } from '../../../api/apiOperation';

import { idbConfig } from '../configs/idbConfig';
import setupIndexedDB, { useIndexedDBStore } from "use-indexeddb";

import { useNavigate } from 'react-router-dom';

const Manager = ({ id, latitude, longitude, accuracy, isUnassigned, productsArray, resetOperations, loading, setLoading }) => {
    const { user, storedUser } = useAuth();

    const navigate = useNavigate();

    const [dataInput, setDataInput] = useState({ 
        qr_code_id: id, 
        latitude: latitude, 
        longitude: longitude, 
        accuracy: accuracy,
        desc: undefined,
        product_id: undefined, 
        time: Date.now()
    })
    const { connectionStatus, checkConnectionSpeed } = useConnectionStatus();
    const { add } = useIndexedDBStore("operations");

    useEffect(() => {
        setupIndexedDB(idbConfig)
            .then(() => console.log("IDB collegato"))
            .catch(e => console.error("IDB errore", e));
    }, []);

    
    const handleInput = (e) => {
        setDataInput({ ...dataInput, [e.target.name] : e.target.value});
    }

    const handleSubmit = (operation) => {
        if(!(dataInput.latitude && dataInput.longitude) || (dataInput.accuracy > 150)) {
            if([1,3].includes(operation)) {
                if(!dataInput.address || dataInput.address == "") {
                    alert('Devi inserire un indirizzo.');
                    return;
                }
            }

            if(operation==1 && (!dataInput.product_id || dataInput.product_id == "")) {
                alert('Devi inserire un prodotto.');
                return;
            }
        }
        
        
        sendOperation(operation);
    }

    const sendOperation = async (operation) => {
        setLoading(true);
        const operationObject = {...dataInput, operation_type_id: operation}
        const token = user.token||storedUser.token;
        const connectionSpeed = checkConnectionSpeed();

        let message = {
            text: '',
            type: ''
        }
        console.log(connectionSpeed.effectiveType)
        if(connectionStatus && (!['slow-2g','2g'].includes(connectionSpeed.effectiveType))) {
            const res = (operation==1) ? await assignQR(operationObject, token) : await storeOperation(operationObject, token);
            message = {
                text: 'Operazione effettuata',
                type: 'success'
            }
            if(res.data && res.data.error) {
                console.log('Errore invio operazione, salvo in IDB');
                message = {
                    text: 'Operazione salvata in locale',
                    type: 'warning'
                }

                add(operationObject).then(console.log)
            }
        } else {
            message = {
                text: 'Operazione salvata in locale, verrà sincronizzata online',
                type: 'success'
            }
            add(operationObject).then(console.log)
        }
        resetOperations();
        setLoading(false);
        navigate('/', { state: { message: message } })
    }


    return (
        <>
        
        <div className="mb-4">
            <form>
                {(!(latitude&&longitude) || (accuracy > 150)) && 
                    <>
                        <div className="bg-warning  text-dark bg-opacity-25 p-2">
                            <label className="py-2"><small>Coordinate non attendibili, inserisci un indirizzo (es. Via del Poggio 18, 50060 Diacceto FI)</small></label>
                            <input className="form-control" id="address" name="address" type="text" value={dataInput.address} onChange={ handleInput }/>
                        </div>
                    </>
                }
                
                <div className="py-2">
                    <div>
                        <label className="py-2"><small>Inserisci eventuali note</small></label>
                        <textarea style={{minHeight:"calc(1.5em + 0.75rem + 95px)"}} className="form-control" id="desc" name="desc" value={dataInput.desc} onChange={ handleInput } />
                    </div>
                </div>

                { isUnassigned ? 
                
                <>
                

                    <div className="py-2">
                        <div>
                            <label className="py-2"><small>Associa prodotto</small></label>
                            <select className="form-control" name='product_id' id='product_id' onChange={handleInput}>
                                <option value="">Seleziona prodotto</option>
                                {productsArray.map((p) => {
                                    return <option key={p.id} value={p.id}>{p.id} - {p.name}</option>;
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="text-center py-2 my-2">
                        <input type="button" className="btn btn-secondary w-100" onClick={()=>{handleSubmit(1)}} disabled={loading} value="Effettua Installazione"/>
                    </div>
                </> 

                :
                
                <>
                    <div className="text-center py-2 my-2">
                        <input type="button" className="btn btn-primary w-100"  onClick={()=>{handleSubmit(2)}} disabled={loading} value="Effettua Manutenzione"/>
                    </div>
                    <div className="text-center py-2 my-2">
                        <input type="button" className="btn btn-secondary w-100" onClick={()=>{handleSubmit(3)}} disabled={loading} value="Effettua Spostamento"/>
                    </div>
                </>

                }   

                <div className="text-center py-2 my-2">
                    <input type="button" className="btn btn-danger w-100" onClick={resetOperations} value="Annulla"/>
                </div> 

            </form>  
        </div>
        </>
    )
}

export default Manager