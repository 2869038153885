export const LOGIN_TEXTS = {
    ERRORS: {
        ERR_BAD_RESPONSE: 'Non è stato possibile effettuare il login.',
        WRONG_CREDENTIALS: 'Email o Password errate.'
    },
}

export const INITIAL_DATAS_TEXTS = {
    ERRORS: {
        GENERIC: 'Non è stato possibile caricare i prodotti.',
        ERR_BAD_RESPONSE: 'Non è stato possibile caricare i prodotti.'
    },
}
