export const idbConfig = {
    databaseName: "Panda",
    version: 1,
    stores: [
      {
        name: "operations",
        id: { keyPath: "id", autoIncrement: true },
        indices: [
          { name: "qr_code_id", keyPath: "qr_code_id"},
          { name: "latitude", keyPath: "latitude" },
          { name: "longitude", keyPath: "longitude" },
          { name: "operation_type_id", keyPath: "operation_type_id" },
          { name: "desc", keyPath: "desc" },
          { name: "product_id", keyPath: "product_id" },
          { name: "time", keyPath: "time" },
        ],
      },
    ],
  };

  // { qr_code_id: "", latitude: "", longitude: "", operation_type_id:"",desc:"",product_id:"", time:'' }