import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logOut, logIn, clearErrorsUsers, setUser } from '../redux/slices/userSlice';

import { useNavigate } from 'react-router-dom';

import { useLocalStorage } from '.';

import { INITIAL_USER } from '../constants/initials';

const useAuth = () => {
    const { user, loadingUser, errorUser } = useSelector(state => state.user)
    const [ storedUser, setStoredUser, deleteStoredUser ] = useLocalStorage('user', INITIAL_USER.AUTH)

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        if(user.isAuth && user.remember) {
            setStoredUser(user);
        }
    }, [user.isAuth, user.remember]);

    useEffect(() => {
        if(!user.isAuth && storedUser.isAuth) {
            dispatch(setUser(storedUser));
        }
    }, [storedUser]);

    useEffect(() => {
        dispatch(clearErrorsUsers());
    }, [dispatch]);

    const userLogOut = () => {
        (user.isAuth || storedUser.isAuth) && dispatch(logOut());
        deleteStoredUser('user');
        navigate('/login')
    }

    const userLogIn = (data) => {
        dispatch(logIn(data));
    }

    return { userLogOut, userLogIn, user, storedUser, loadingUser, errorUser };
}

export default useAuth;