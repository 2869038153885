import axios from 'axios';
import { ENDPOINTS_LOGIN } from '../constants/endpoint';

export const userLogin = async (data) => {
    try {
        const res = await axios.post(ENDPOINTS_LOGIN.LOGIN, data)
        return res;
    } catch(e) {
        return {
            data: {
                error: true,
                code: e.code
            }
        };
    }
}
