import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { useLocation } from 'react-router-dom';

const Home = () => {

  const location = useLocation();

  console.log(location.state)
  return (
    <> 
      {location.state&&<div className={`alert alert-${location.state.message.type}`} role="alert">
        {location.state.message.text}
      </div>}
       <div className="text-center py-2 my-2">
          <Link className="btn btn-primary w-100" to='/operation'>Avvia Scansione</Link>
      </div> 
    </>
  )
}

export default Home;
