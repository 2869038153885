import React, { useState, useEffect } from 'react'
import { getTodayTasks } from '../../api/apiOperation';
import { useAuth, useLocalStorage, useConnectionStatus } from '../../hooks';
import { Link } from 'react-router-dom';
import { URL_MAPS } from '../../constants/endpoint';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';

const Circuit = () => {
  const [ tasks, setTasks] = useState([]);
  const { user, storedUser } = useAuth();
  const [ storedTasks, setStoredTasks, deleteStoredTasks ] = useLocalStorage('tasks', [])

  let url_percorso = URL_MAPS.PATH+URL_MAPS.DIR;
  let url_singolo_bagno = URL_MAPS.PATH+URL_MAPS.SEARCH;
  let urls_array = [];
  const numero_task=tasks.length-1;
  const step=20;
  let limite=step;
  const { connectionStatus } = useConnectionStatus();
  
  const getTasks = async () => {
    const token = user.token||storedUser.token;
    const res = await getTodayTasks(token);
    setTasks(res.data.data)
  }

  useEffect(() => {
      if(tasks.length > 0) {
          setStoredTasks(tasks);
      }
  }, [tasks]);

  useEffect(() => {
    getTasks();
  },[]);

  useEffect(() => {
    getTasks();
  },[connectionStatus]);

  return (
    <> 
      {
        tasks.length>0 
        ? <>
          <div className="text-center py-3 h5"><span>Interventi Programmati</span></div>
          <ol className="list-group list-group-numbered list-group-flush my-2">
            { 
              tasks.map(( task, i) => { 
                  const toDo = task.da_effettuare == 'no' ? false : true;
                  const link_singolo = (task.product_lat.length>0 && task.product_long>0) ? url_singolo_bagno+task.product_lat+","+task.product_long : false;
                  url_percorso=(task.product_lat.length>0 && task.product_long>0) ? url_percorso + task.product_lat + "," +task.product_long +"/" : url_percorso

                  if (i == limite){
                    urls_array.push(url_percorso);
                    url_percorso=URL_MAPS.PATH+URL_MAPS.DIR;
                    limite=limite+step;
                  }
                  if (i==numero_task) {
                    urls_array.push(url_percorso);
                  }
                  return (
                  <li key={task.id} className={`list-group-item border-0 mb-1 d-flex justify-content-between align-items-start${toDo?' bg-light':' bg-success text-white'}`}>
                      <div className="ms-2 me-auto">
                          <div className="fw-bold h6">{task.id} - {task.product} (QR: {task.product_qr})</div>
                          <span className="h6">{task.indirizzo}</span>
                      </div>
                      {toDo&&<span className="align-self-center h5 mb-0">{link_singolo?<Link to={link_singolo} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faMapMarkedAlt}/></Link>:'No coordinate'}</span>}
                  </li>
                )
              })
            }
          </ol>

          <div>
            {
              urls_array.map(( url, i) => { 
                return (<a className="btn btn-primary mt-3 w-100" href={url} target="_blank">Mappa Persorso {i+1}</a>)
              })
            }
          </div>
          </>
        :
          <div className="text-center py-3 h5"><span>Non ci sono interventi programmati</span></div>
      }
    </>
  )
}

export default Circuit;
